@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    font-family: 'Roboto', sans-serif;
    background-color: #000;
    color: #fff;
}
@layer utilities {
    .custom-gradient {

        background-size: 40px 40px;
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    }
}
input{
    outline:none;
    outline:0
}

.snowflake {
    @apply bg-white rounded-full;
    position: fixed;
    width: 10px;
    height: 10px;
    z-index: 1000;
    animation-name: fall;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes fall {
    to {
        transform: translateY(100vh);
    }
}

.rouletSlider {

    position: relative;
    display: inline-flex;
    left: 0;
    transform: translate3d(0, 0, 0);
    list-style: none;
    transition: 20s cubic-bezier(.21, .53, .29, .99);
}

