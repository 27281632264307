/* src/components/DinoGame.css */
.game-container {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #f2f2f2;
    overflow: hidden;
}

.dino {
    position: absolute;
    bottom: 0;
    left: 50px;
    width: 50px;
    height: 50px;
    transition: 0.5s;
}

.dino.jump {
    bottom: 100px;
}

.obstacle {
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 20px;
    background-color: #ff0000;
}

.score {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #333;
}
